import { render, staticRenderFns } from "./PageEN.vue?vue&type=template&id=2a267e59&scoped=true"
import script from "./PageEN.vue?vue&type=script&lang=js"
export * from "./PageEN.vue?vue&type=script&lang=js"
import style0 from "./PageEN.vue?vue&type=style&index=0&id=2a267e59&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a267e59",
  null
  
)

export default component.exports